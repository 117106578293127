
import { Component, Vue } from 'vue-property-decorator';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import { AgGridVue } from 'ag-grid-vue';
import { getComponent, getConfigEnv, sleep } from '@/utils/helpers';
import FileUpload from '@/components/tasqsCommon/FileUpload.vue';
import workflowModule from '../store/modules/workflowModule';
import CustomTextCell from '@/views/agGrid/CustomTextCell.vue';
import accountModule from '@/store/modules/accountModule';
// import { getNameByEmail } from '@/utils/users';

@Component({
  components: {
    AppLayout: () => getComponent('common/AppLayout'),
    TasqLoader: () => getComponent('loaders/TasqLoader'),
    AgGridVue,
    FileUpload,
    CustomTextCell
  },
})
export default class TicketSupport extends Vue {
  context: any = null;

  columnDefs: any = [];

  isSavingBugTicket = false;

  showBugTicketPopup = false;

  hideAddImages = false;

  defaultBugForm = {
    title: '',
    description: '',
  };

  bugForm = {
    title: '',
    description: '',
  };

  shortcutApiUrl = 'https://api.app.shortcut.com/api/v3/search/stories';

  defaultColDef = {
    initialWidth: 250,
    sortable: true,
    filter: true,
    editable: false,
    autoHeight: true,
    cellStyle: {
      borderBottom: '1px solid rgba(255, 255, 255, 0.1)',
      overflow: 'visible',
      'white-space': 'normal',
    },
    resizable: false,
  };

  rowData: any = [];

  cellClick() {
    // console.log(e);
  }

  supportTicketFiles: any = [];

  updateTicketFiles(files) {
    this.supportTicketFiles = files;
  }

  getRowNodeId: any = null;
  searchLabels: any = ['label:"visible to all"'];

  gridApi: any = null;

  gridColumnApi: any = null;

  dataLoading = false;

  get operatorNameForShortcut() {
    const name = getConfigEnv('OPERATOR_PASCALED').toLowerCase();
    if (name === 'petronascanada') {
      return 'petronas';
    }
    return name;
  }

  getUniqueObjectsByField(objects, field) {
    const uniqueObjectsByField = [];

    objects.forEach((object) => {
      if (
        !uniqueObjectsByField.some(
          (otherObject) => otherObject[field] === object[field]
        )
      ) {
        uniqueObjectsByField.push(object);
      }
    });

    return uniqueObjectsByField;
  }

  beforeMount() {
    this.context = { componentParent: this };
    // @ts-ignore
    window.gridApiVue = this;
  }

  resetForm(){
    this.hideAddImages = true
    this.bugForm = {
      title: '',
      description: '',
    };
    this.supportTicketFiles = [];
    this.$nextTick(()=> {
      this.hideAddImages = false
    })

  }

  labelID: any = [];

  async created() {
    this.rowData = [];
    this.searchLabels.push(
      `label:"visible to ${this.operatorNameForShortcut}"'`
    );

    this.dataLoading = true;

    const issuesLabels =  await workflowModule.gettLinearIssuesLabels()
   if(issuesLabels && issuesLabels.length){
    this.labelID =  issuesLabels.map(i => i.id)
   }

    await this.getOperatorShortcutStories();
    this.dataLoading = false;

  }

  async getOperatorShortcutStories() {
    const stories =  await workflowModule.gettLinearIssues()
    if (stories && stories.length) {
      this.rowData = stories
        .map((story) => {
          return {
            name: story.title,
            deadline:
              story.dueDate && story.dueDate.length > 3
                ? story.dueDate
                : null,
                deadlineTimestamp:
              story.dueDate && story.dueDate.length > 3
                ? new Date(story.dueDate).getTime()
                : 0,
            description:
              story.description && story.description.length > 1
                ? story.description.split('-------')[0]
                : '-',
          };
        })
        .sort((a, b) =>  (a.deadlineTimestamp) - (b.deadlineTimestamp))
    }
  }

  async onGridReady(params) {
    this.gridApi = params.api;
    // this.rowData = [];
    this.columnDefs = [];
    this.gridColumnApi = params.columnApi;
    this.gridApi.hideOverlay();

    this.columnDefs = [
      {
        field: 'name',
        cellClass: 'noborder',
        cellStyle: {
          borderBottom: '1px solid rgba(255, 255, 255, 0.1)',
          overflow: 'visible',
          'white-space': 'normal',
          borderTop: 'none',
          borderRight: 'none',
          borderLeft: '1px solid rgba(255, 255, 255, 0.1)',
        },
        sortable: true,
        filter: true,
        width: 390,
        colId: 'name',

        headerName: 'TICKET NAME',
      },
      // {
      //   field: 'description',
      //   width: 480,
      //   autoHeight: true,
      //   colId: 'description',
      //   cellStyle: {
      //     color: 'white',
      //     'text-overflow': 'ellipsis',
      //     lineHeight: 1.6,


      //     margin: '0px',
      //     borderTop: 'none',
      //     borderRight: '1px solid rgba(255, 255, 255, 0.1)',
      //     borderLeft: '1px solid rgba(255, 255, 255, 0.1)',
      //     borderBottom: '1px solid rgba(255, 255, 255, 0.1)',
      //   },
      //   cellClass: 'noborder',
      //   cellEditorParams: {
      //     maxLength: 1000,
      //   },
      //   cellEditorPopup: true,
      //   cellRendererFramework: 'CustomTextCell',

      //   headerName: 'Description',
      // },
      {
        field: 'deadline',
        editable: false,
        width: 180,
        colId: 'deadline',
        cellStyle: {
          borderBottom: '1px solid rgba(255, 255, 255, 0.1)',
          overflow: 'visible',
          'white-space': 'normal',
          borderTop: 'none',
          borderRight: '1px solid rgba(255, 255, 255, 0.1)',
          borderLeft: '1px solid rgba(255, 255, 255, 0.1)',
        },
        valueFormatter: (params) => {
          if (
            params.value === '-' ||
            params.value === null ||
            params.value === undefined
          ) {
            return '-';
          }
          return new Date(params.value).toDateString();
        },
        cellClass: 'noborder',
        headerName: 'ESTIMATED COMPLETION DATE ',
      },
    ];

    await sleep(1000);
    this.gridColumnApi.autoSizeColumns();
  }

  onFirstDataRendered(params) {
    this.gridApi.sizeColumnsToFit();
  }

  async submitBugForm() {
    this.isSavingBugTicket = true;
    const bugForm = this.bugForm;
    await sleep(1000);
    bugForm['description'] = bugForm['description'] +`\n\n -------` + accountModule.user.email + `\n\n -------`;
    if (this.supportTicketFiles.length) {
      const filesUrls = this.supportTicketFiles.map((file) => {
        return `![${file.name}](${encodeURI(file.url)})`;
      });
      bugForm['description'] = `${bugForm['description']} \n ${filesUrls.join(
        '\n-----------------------\n'
      )}`;
    }
    await sleep(1000);
    await workflowModule.postLinearBug({...bugForm, labelID: this.labelID });
    await sleep(1000);
    await this.getOperatorShortcutStories();
    this.isSavingBugTicket = false;
    this.bugForm = {
      title: '',
      description: '',
    };
    this.supportTicketFiles = [];
    this.showBugTicketPopup = false;
  }
}
